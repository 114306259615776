<template>
    <div>
		<b-modal ref="modalSendMail" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group mt-4">
					<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
					<e-select
						v-model="finalExpeditor"
						id="expeditor_id"
						:placeholder="labelTitleExpeditor"
						:options="finalExpeditors"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"        
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Common from '@/assets/js/common.js'
import Model from '@/mixins/Model.js'
import Tiers from '@/mixins/Tiers.js'

export default {
	name: "ModalSendMail",
	mixins: [Model, Tiers],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			selected_ids: null,
			form_message: '',
            model_type: '',
            model_type_template: '',
			finalExpeditors: [],
            finalExpeditor: null,
            nomail: false,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleExpeditor: this.getTrad("model.search_expeditor")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = []

			this.templates = await this.loadAllModel(0, [this.model_type], this.modeltype_template)
			
			const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)
		},
		openModal(selected_ids, model_type, modeltype_template) {
			this.selected_ids = selected_ids
            this.model_type = model_type
            this.modeltype_template = modeltype_template

			this.form_message = ''
			if(this.selected_ids.length > 5) {
				this.form_message = this.getTrad("global.warning_lots")
			}

			this.$refs.modalSendMail.show()
		},
		closeModal() {
			this.$refs.modalSendMail.hide()
		},
		async FormatFromAddress(fromAddress){
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)){				
				if (value.licenceconfig_key === "from_address"){
					if (value.licenceconfig_value != '' || value.licenceconfig_value != null){
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress){
							array_mail.push(address.trim())
						}
					} 					
				}
				if (value.licenceconfig_key === "from_name" ){
					if (value.licenceconfig_value != ''|| value.licenceconfig_value != null){
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName){
							array_name.push(name.trim())
						}
					} 
				}
				if(array_mail.length > 0 && array_name.length > 0){
					if(array_name[0] === 'Groomy'){
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail){
				this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},
		async checkForm() {
			if(this.template) {
				this.processing = true
				
				if(this.model_type === 'tiers_notification') {
					let model_ids = []
					await Common.asyncForEach(this.selected_ids, async (tiers_id) => {
						const model = await this.createModel(tiers_id, this.template.type.modeltype_id, this.template.model_label, this.template.type.modeltype_template)
						await this.cloneModel(this.template.model_id, model.model_id)
						await this.generateCourrier(model.model_id, this.template.type.modeltype_template, tiers_id)
						model_ids.push(model.model_id)
					})

					await this.sendTiersMail(model_ids, this.finalExpeditor)
					    .then((res) => {
							this.processing = false

							if(res != 'error') {
								this.successToast('gynecologie.send_dg_success')
								this.closeModal()
							}
                    	})
				}
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		}
	}
}
</script>